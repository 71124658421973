// @ts-nocheck

import './scss/index.scss';

import 'swiper/css';
import 'swiper/css/navigation';



import Swiper from 'swiper';
import { Navigation} from 'swiper/modules';

import HystModal from 'hystmodal';

import tippy from "tippy.js";

import JustValidate from 'just-validate';
import IMask from 'imask';


// console.log('hello');

/* Слайдер */
const dillerSlider = new Swiper('.diller-slider__slider', {
    // loop: true,
    slidesPerView: 1,
    direction: 'horizontal',
    modules: [Navigation],
    
    navigation: {
        nextEl: '.diller-slider__next',
        prevEl: '.diller-slider__prev',
    },
});



// Добавляем обработчик события нажатия на кнопку влево

console.log(dillerSlider);

/* Подсказки в профильных системах */
const elemetsTippy = document.querySelectorAll('.js-helper-info');

elemetsTippy.forEach((item) => {
    tippy(item, {
        hideOnClick: true,
        trigger: "click",
        // arrow: false,
        // offset: [0, -10],
        maxWidth: '200px',
        theme: 'helper',
        // content: 'Воздушные камеры в раме/створке/импосте',
    });
})

/* Почему выбирают нас */
    const el = document.querySelector(".js-why-work");

    const slider = el.querySelector(".js-why-work--slider");

    let winWidth = window.innerWidth;

    //if (slider && winWidth < 1200) {
    new Swiper(slider, {
        slidesPerView: 1,
        spaceBetween: 30,
        //centeredSlides: true,
        //slidesPerView: "auto",
        // centeredSlidesBounds: true, // добавляем эту опцию
        breakpoints: {
            320: {

                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    });


/* Отзывы */
    const dillerFeedback = new Swiper('.diller-feedback__slider', {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        modules: [Navigation],
        
        navigation: {
            nextEl: '.diller-feedback__next',
            prevEl: '.diller-feedback__prev',
        },

        breakpoints: {
            375: {
                slidesPerView: 1,
            },

            576: {
                slidesPerView: 2,
            },

            991: {
                slidesPerView: 3,
            }
        }
    });

/* Формы */
    const postData = async (url, data) => {
        const res = await fetch(url, {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: data
        });
    
        return await res.json();
    }

    const validate = (formValid) => {
        const inputs = formValid.form.querySelectorAll('input');

        inputs.forEach(input => {
            formValid
            .addField(input,[
                {
                    rule: 'required',
                    errorMessage: 'Это поле обязательно'
                }
            ])
        });

    }
    
    window.formMeas = new JustValidate(`#form-meas`);
    window.formQuest = new JustValidate(`#form-question`);
    window.formCall = new JustValidate(`#form-call`);


    const initForm = (id) => {
        const form = document.querySelector(id);
        const inputs = form.querySelectorAll('input');

        if(id == '#form-meas') {
            validate(window.formMeas);
        } else if(id == '#form-question'){
            validate(window.formQuest);
        } else {
            validate(window.formCall);
        }
        
        const telInput = form.querySelectorAll('[type="tel"]');

        telInput.forEach(tel => {
            IMask(tel, {
                mask: '+{7}(000)000-00-00',
                lazy: true,
                placeholderChar: '_'
              })
        })

        form.addEventListener('submit', (e) => {
            e.preventDefault();

            const formData = new FormData(form);
            const json = JSON.stringify(Object.fromEntries(formData.entries()));
            if(id == '#form-meas') {
                window.formMeas
                .revalidate().then(isValid => {
                    if(isValid) {
                        console.log(json)
                        form.reset();
                    }
                })
            } else if(id == '#form-question'){
                window.formQuest
                .revalidate().then(isValid => {
                    if(isValid) {
                        console.log(json)
                        form.reset();
                    }
                })
            } else {
                window.formCall
                .revalidate().then(isValid => {
                    if(isValid) {
                        console.log(json)
                        form.reset();
                    }
                })
            }

            /*             postData('path', json)
                .then(data => {
                    console.log(data);
                })
                .catch(() => {
                    console.log("Что-то пошло не так...")
                })
                .finally(() => {
                    form.reset();
                }); */
        })
    }

    initForm('#form-meas');
    initForm('#form-question');
    initForm('#form-call');


/* Галерея */
    let gallery = document.querySelector('.js-work-gallery-slider');
	let arrowLeft = document.querySelector('.js-work-gallery-slider .js-arrow-left');
    let arrowRight = document.querySelector('.js-work-gallery-slider .js-arrow-right');
	let workGallery = new Swiper(gallery, {
		loop: true,
		spaceBetween: 20,
		slidesPerView: 1,
		centeredSlides: true,
        modules:[Navigation],
        navigation: {
            nextEl: arrowRight,
            prevEl: arrowLeft,
          },
	});



    if(window.innerWidth <= 1024) {
        const burger = document.querySelector('.burger');
        const menu = document.querySelector('.diler-header__list');
        const links = menu.querySelectorAll('.diler-header__link');
    
        burger?.addEventListener('click', (e) => {
            burger.classList.toggle('js-active');
            menu.classList.toggle('js-active');

            links.forEach(link => {
                link.addEventListener('click', (e) => {
                    burger.classList.remove('js-active');
                    menu.classList.remove('js-active');
                    document.querySelector('body').style.overflow = '';
                    document.querySelector('body').style.height = '';
                })
            })

            if(menu.classList.contains('js-active')) {
                document.querySelector('body').style.overflow = 'hidden';
                document.querySelector('body').style.height = '100vh';
            } else {
                document.querySelector('body').style.overflow = '';
                document.querySelector('body').style.height = '';
            }
        })
    }


/* Карта */
    let elMap = document.querySelector(`.js-diller-location--map`);
    let points = JSON.parse(elMap.dataset.points);
    let mapObject;

    let mapInit = () => {

        ymaps.ready(() => {
            mapObject = new ymaps.Map(elMap, {
                center: points[0].split(','),
                zoom: 14,
                controls: ['zoomControl'],
            }, {
                searchControlProvider: 'yandex#search'
            });

            //добавляем метки
            points.forEach(point => {
                mapObject.geoObjects.add(new ymaps.Placemark(point.split(','), {iconLayout: 'default#image',}))
            })
            mapObject.setBounds(mapObject.geoObjects.getBounds());

            if(window.innerWidth <= 768) {
                mapObject.setZoom(13)
            } else {
                mapObject.setZoom(14)
            }
            mapObject.behaviors.disable('scrollZoom');

            // window.setCenter = function(lat, lon) {
            //     mapObject.panTo([
            //         [lat, lon]
            //     ]);
            // }

        });
    }

    (() => {
        let script = document.createElement('script');
        script.src = "https://api-maps.yandex.ru/2.1.78/?apikey=d3f6da6d-fdd3-440f-b292-a9313f38ff7f&lang=ru_RU&oordorder=longlat";
        document.head.append(script);

        script.onload = () => {

            // console.log(ymaps);
            // loadMap(ymaps);

            mapInit();
        }
    })();

    /* Таймер */
    let elCounter = document.querySelector(`.js-diller-timer--set`);
    let deadline = elCounter.dataset.deadline;

    let days = elCounter.querySelector('#js-days');
    let hours = elCounter.querySelector('#js-hours');
    let minutes = elCounter.querySelector('#js-minutes');
    let seconds = elCounter.querySelector('#js-seconds');

    /**
     * Создаём функцию которая будет высчитывать разницу между нынешней датой и датой окончания акции :
     *   1) Создаём переменные для помещение числовых значений.
     *   2) Создаём техническую переменную в которую получаем разницу в милисекундах
     *   3) Создаём проверку на отрицательное значение
     *   4) Если t = 0 и меньше - показывать нули, если > нуля - преобразовывать милисекунды в нужные данные
     *   5) Вернуть объект с получившимся значениями.
     * @param {*} endtime
     * @returns
     */
    let getTimeRemaining = (endtime) => {
        let tdays = 0,
            thours = 0,
            tminutes = 0,
            tseconds = 0;
        let nowDate = ((new Date()).toJSON()).split('.')[0];
        let t = Date.parse(endtime) - Date.parse(nowDate);
        if (t > 0) {
            tdays = Math.floor(t / (1000 * 60 * 60 * 24)),
            thours = Math.floor((t / (1000 * 60 * 60) % 24)),
            tminutes = Math.floor((t / 1000 / 60) % 60),
            tseconds = Math.floor((t / 1000) % 60);
        }

        return {
            total: t,
            days: tdays,
            hours: thours,
            minutes: tminutes,
            seconds: tseconds
        };
    }

   /**
    * Создаём функцию подстановки нуля однозначным числам.
    *
    * @param {*} num
    * @returns
    */
    let getZero = (num) => {
        if (num >= 0 && num < 10) {
            return `0${num}`;
        } else {
            return num;
        }
    }

    /**
     * Функция которая будет показывать время на странице:
     * Создаём внутри функцию обновления таймера:
     * - помещаем в техническую переменную (t) возвращенный объект функцией getRemaining().
     * - помещаем в каждый полученный элемент соответствующее значение объекта с вызовом функции getZero().
     * - если общее число милисекунд меньше нуля, таймер не обновляется.
     */
    let updateClock = () => {
        let t = getTimeRemaining(deadline);

        days.textContent = getZero(t.days);
        hours.textContent = getZero(t.hours);
        minutes.textContent = getZero(t.minutes);
        seconds.textContent = getZero(t.seconds);

        if (t.total <= 0) {
            clearInterval(timeInterval);
        }
    }

    let timeInterval;

    (() => {
        timeInterval = setInterval(updateClock, 1000);
        updateClock();
    })();

// Модальные окна
    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
        closeOnOverlay: true,
        closeOnEsc: true,
    });

    function hiddenArrows(slider) {
        if(slider.slides.length > 1) {
            document.querySelector('.swipe-button-next').style='flex';
            document.querySelector('.swipe-button-prev').style='flex';
        } else {
            document.querySelector('.swipe-button-next').style='none';
            document.querySelector('.swipe-button-prev').style='none';
        }
    }

    hiddenArrows('.diller-slider__slider');
    hidden('.diller-feedback__slider');
    hiddenArrows('.work-gallery__slider');